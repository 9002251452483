import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,
} from "react-bootstrap";
import "./WhyUse.scss";


const WhyUse = (props) => {

  return (
    <React.Fragment>
      <section className="why-use-location">
        <Container>
          <Row>
          <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
            <Col md={12}><span className="small-heading">Why use Location Location?</span></Col>
            <Col md={12}><h2>Full-service property management to London landlords.</h2></Col>
          </ScrollAnimation>
          </Row>
          <Row className="why-use-cols">
            <Col lg={4}  md={6} className="order-1-md">
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
            <div className="why-use-block">
              <h6>Service.</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet auctor libero eu pharetra. In ultrices.</p>
            </div>
            </ScrollAnimation>  
            </Col>
            <Col lg={4}  md={6} className="order-3-md order-lg-2">
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">  
            <div className="why-use-block">
              <h6>Availability.</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet auctor libero eu pharetra. In ultrices.</p>
            </div>
            </ScrollAnimation>  
            </Col>
            <Col lg={4}  md={6}  className="order-5-md order-lg-3">
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">  
            <div className="why-use-block border-none-md  pb-mb-md-0 border-lg-1">
              <h6>Trusted.</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet auctor libero eu pharetra. In ultrices.</p>
            </div>
            </ScrollAnimation>    
            </Col>
            <Col lg={4}  md={6} className="order-2-md order-lg-4">
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
            <div className="why-use-block  pb-mb-lg-0  border-bottom-lg-none">
              <h6>Responsive.</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet auctor libero eu pharetra. In ultrices.</p>
            </div>
            </ScrollAnimation>  
            </Col>
            <Col lg={4}  md={6}  className="order-4-md ">
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
            <div className="why-use-block pb-mb-lg-0 border-bottom-lg-none">
              <h6>Experience.</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet auctor libero eu pharetra. In ultrices.</p>
            </div>  
            </ScrollAnimation> 
            </Col>
            <Col lg={4}  md={6}  className="order-6-md">
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
            <div className="why-use-block pb-mb-lg-0 border-bottom-lg-none">
              <h6>Local-knowledge.</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet auctor libero eu pharetra. In ultrices.</p>
            </div>  
            </ScrollAnimation>
            </Col>

            
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default WhyUse;
