import React,{useEffect, useState} from "react";
import Header from "../../Components/Header/Header";
// import Search from "../../Components/Search/Search";
import Footer from "../../Components/Footer/Footer";
import PropertyManagementBanner from "../../Components/PropertyManagement/PropertyManagementBanner/PropertyManagementBanner";
import PropertyManagementSection from "../../Components/PropertyManagement/PropertyManagementSection/PropertyManagementSection";
import WhyUse from "../../Components/PropertyManagement/WhyUse/WhyUse";
import PropertyManagementGoogleReview from "../../Components/PropertyManagement/PropertyManagementGoogleReview/PropertyManagementGoogleReview";
import AreaGuides from "../../Components/PropertyManagement/AreaGuides/AreaGuides";
import ManagementGoogleReview from "../../Components/PropertyManagement/ManagementGoogleReview/ManagementGoogleReview";
import RelatedPropertyManagement from "../../Components/PropertyManagement/RelatedPropertyManagement/RelatedPropertyManagement";
import CalculateStampDuty from "../../Components/PropertyManagement/CalculateStampDuty/CalculateStampDuty";
import QuizQuestions from "../../Components/PropertyManagement/QuizQuestions/QuizQuestions";
import QuizContacts from "../../Components/PropertyManagement/QuizContacts/QuizContacts";


const PropertyManagement = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
  }, [])
  
  return (
    <React.Fragment>
      <div className={scroll ? "menu-fixed menu2 menu-scroll" : "menu-fixed menu2"}><Header/></div>
      <PropertyManagementBanner/>
      <PropertyManagementSection/>
      <WhyUse/>
      <PropertyManagementGoogleReview/>
      <AreaGuides/>
      <ManagementGoogleReview/>
      <RelatedPropertyManagement/>
      <CalculateStampDuty/>
      <QuizQuestions/>
      <QuizContacts/>
      <div className="footer-property-management"><Footer/></div>
    </React.Fragment>
    );
};

export default PropertyManagement;
