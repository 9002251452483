import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,} from "react-bootstrap";
import "./CalculateStampDuty.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import stampImgDesk  from "../../../images/PropertyManagement/stamp-img.png";
import stampImgTab  from "../../../images/PropertyManagement/stamp-img.png";
import stampImgMob  from "../../../images/PropertyManagement/stamp-img.png";

const CalculateStampDuty = (props) => {
  
  return (
    <React.Fragment>
        <div className="calculate-section">
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeIn">
          <Form>
          <div className="stamp-duty-form">
            <Form.Group>
              <Form.Label>Property Purchase Price</Form.Label>
              <div className="form-addon-input-left form-border-input">
              <span>&#163;</span><Form.Control type="number" placeholder="1575000"/>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Deposit</Form.Label>
              <div className="form-addon-input-left form-border-input">
              <Form.Control type="email" placeholder=""/>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Annual Interest</Form.Label>
              <div className="form-addon-input-right form-border-input">
              <Form.Control type="number" placeholder="2.4"/><span>%</span>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Repayment Period</Form.Label>
              <div className="form-addon-input-right form-border-input">
              <Form.Control type="number" placeholder="25"/><span>years</span>
              </div>
            </Form.Group>
            </div>
            <div className="monthly-repayment-form">
              <div className="monthly-repayment-text">
              <label>Monthly Repayments</label>
              <p className="mrp-amount">&#163;6,917 <span> / month</span></p>
              </div>
             <div className="find-property-block">
             <div className="find-property-block-dt">
             <span>Ready to find a property?</span>
              <p className="description">Lorem ipsum dolor sit amet consect.</p>
              </div>  
              <Link to="/property/for-sale/in-stoke-newington-and-hackney-and-north-london-and-east-london/">
              <div className="find-property-btn"><Button variant="yellow" type="submit">Property Search</Button></div>
              </Link>
              
             </div>
             
              <div className="stamp-enquiry-img d-none">
                <div className="img-thumb">
                <picture>
                  <source media="(min-width:992px)" srcSet={stampImgDesk} />
                  <source media="(min-width:768px)" srcSet={stampImgTab} />
                  <img src={stampImgMob} alt="" />
                </picture>
                </div>
                <div className="img-thumb-data">
                  <p className="name">Laura White</p>
                  <p className="contact-dt"><Link href="tel:02079239222" className=" btn-text-link mt-0">0207 923 9222</Link> / <Link href="mailto:#"className=" btn-text-link mt-0">Email</Link></p>
                </div>
              </div>
            </div>
          </Form>
          </ScrollAnimation>
          
        </div>
    </React.Fragment>
  );
};

export default CalculateStampDuty;
