import React from "react";
import "./ManagementGoogleReview.scss";
import googleReviewImg from "../../../images/Home/google-review.jpg";
import googleReviewImgTablet from "../../../images/Home/googl-review-tablet.jpg";
import googleReviewImgDesktop from "../../../images/Home/google-review-desktop.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import googleReviewIcon from "../../../images/Home/google-reviews-dark.png";
import googleReviewIconTablet from "../../../images/Home/google-reviews-dark-tablets.png";
import googleReviewIconDesktop from "../../../images/Home/google-reviews-dark-desktop.png";

const ManagementGoogleReview = (props) => {
  return (
    <React.Fragment>
      <section className="homegoogle-reviews d-md-none">
        <ScrollAnimation
        animateOnce={true}
        duration={0.5}
          animateIn="fadeInDown"
          className="google-review-img-block"
        >
          <div className="google-review-img">
            <picture>
              <source
                media="(min-width:992px)"
                srcSet={googleReviewImgDesktop}
              />
              <source
                media="(min-width:768px)"
                srcSet={googleReviewImgTablet}
              />
              <img src={googleReviewImg} alt="Google reviews -  Location Location" />
            </picture>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp" className="review-content-block">
          <div className="google-review-content">
            <div className="google-review-head">
              <span className="google-review-text">4.8/5</span>
              <span className="google-review-icon">
                <picture>
                  <source
                    media="(min-width:992px)"
                    srcSet={googleReviewIconDesktop}
                  />
                  <source
                    media="(min-width:768px)"
                    srcSet={googleReviewIconTablet}
                  />
                  <img src={googleReviewIcon} alt="" />
                </picture>
              </span>
            </div>
            <p>
              I would recommend working with this agency. I found them to be
              professional, relaxed (in a good way) and quick to respond to
              queries. Shout out to David Dyer for helping me through the
              process of renting.
            </p>
            <ul className="ratings">
              <li>
                <i className="icon-star-dark"></i>
              </li>
              <li>
                <i className="icon-star-dark"></i>
              </li>
              <li>
                <i className="icon-star-dark"></i>
              </li>
              <li>
                <i className="icon-star-dark"></i>
              </li>
              <li>
                <i className="icon-star-dark"></i>
              </li>
            </ul>
            <div className="review-detail">
              <p className="name">Alexander Millington</p>
              <p className="review-date">1 Month Ago</p>
            </div>
          </div>
        </ScrollAnimation>
      </section>
    </React.Fragment>
  );
};

export default ManagementGoogleReview;
