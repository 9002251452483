import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,
} from "react-bootstrap";
import "./AreaGuides.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import AreaGuideVideoImgDesk from "../../../images/PropertyManagement/area-guide-img-1-desktop.jpg";
import AreaGuideVideoImgTab from "../../../images/PropertyManagement/area-guide-img-1-tab.jpg";
import AreaGuideVideoImgMob from "../../../images/PropertyManagement/area-guide-img-1-mobile.jpg";
import PlayVideo from '../../Play/PlayVideo';


import AreaGuideVideoImg2Desk from "../../../images/PropertyManagement/area-guide-img-2-desktop.jpg";
import AreaGuideVideoImg2Tab from "../../../images/PropertyManagement/area-guide-img-2-tab.jpg";
import AreaGuideVideoImg2Mob from "../../../images/PropertyManagement/area-guide-img-2-desktop.jpg";


import AreaGuideVideoImg3Desk from "../../../images/PropertyManagement/area-guide-img-3-desktop.jpg";
import AreaGuideVideoImg3Tab from "../../../images/PropertyManagement/area-guide-img-3-desktop.jpg";
import AreaGuideVideoImg3Mob from "../../../images/PropertyManagement/area-guide-img-3-desktop.jpg";


const AreaGuides = (props) => {

  const [isPlay,setPlay] = useState(false);
  const [isPlaying,setPlaying] = useState(false);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  return (
    <React.Fragment>
      <section className="area-guides">
          <Container>
            <Row>
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
              <Col lg={12}><span class="small-heading">Area Guides</span></Col>
              <Col lg={12}><h2>Area guides Lorem ipsum, or lipsum</h2></Col>
            </ScrollAnimation>
            </Row>
          </Container>
          <div className="area-guide-video-carousel">
          <Carousel responsive={responsive} showDots={true} draggable={true} autoPlay={false} swipeable={true} infinite={true} removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
              <div className="item">
                <div className="video-img-box">
                  <div className="video-img-wrapper">
                  <picture>
                    <source media="(min-width:992px)" srcSet={AreaGuideVideoImgDesk} />
                    <source media="(min-width:768px)" srcSet={AreaGuideVideoImgTab} />
                    <img src={AreaGuideVideoImgMob} alt="" />
                  </picture>
                  </div>
                  <a to="#" className="video-arrow" onClick = { e => {setPlay(true)}}><i class="icon-video-white"></i></a>
                </div>
                <div className="video-carouse-content">
                  <p className="name">Dalston</p>
                  <p className="code">E8, N16</p>
                  <p className="description">Up-and-coming</p>
                </div> 
              </div>
              </ScrollAnimation>
              
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
              <div className="item">
                <div className="video-img-box">
                  <div className="video-img-wrapper">
                  <picture>
                    <source media="(min-width:992px)" srcSet={AreaGuideVideoImg2Desk} />
                    <source media="(min-width:768px)" srcSet={AreaGuideVideoImg2Tab} />
                    <img src={AreaGuideVideoImg2Mob} alt="" />
                  </picture>
                  </div>
                  <a to="#" className="video-arrow" onClick = { e => {setPlaying(true)}}><i class="icon-video-white"></i></a>
                </div>
                <div className="video-carouse-content">
                  <p className="name">Finsbury Park</p>
                  <p className="code">N4, N7</p>
                  <p className="description">Loved by commuters</p>
                </div> 
              </div>
              </ScrollAnimation>
              
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
              <div className="item">
                <div className="video-img-box">
                  <div className="video-img-wrapper">
                  <picture>
                    <source media="(min-width:992px)" srcSet={AreaGuideVideoImg3Desk} />
                    <source media="(min-width:768px)" srcSet={AreaGuideVideoImg3Tab} />
                    <img src={AreaGuideVideoImg3Mob} alt="" />
                  </picture>
                  </div>
                  <a href="#" className="video-arrow" onClick = { e => {setPlaying(true)}}><i class="icon-video-white"></i></a>
                </div>
                <div className="video-carouse-content">
                  <p className="name">Dalston</p>
                  <p className="code">E8, N16</p>
                  <p className="description">Up-and-coming</p>
                </div> 
              </div>
              </ScrollAnimation>
              
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
              <div className="item">
                <div className="video-img-box">
                  <div className="video-img-wrapper">
                  <picture>
                    <source media="(min-width:992px)" srcSet={AreaGuideVideoImgDesk} />
                    <source media="(min-width:768px)" srcSet={AreaGuideVideoImgTab} />
                    <img src={AreaGuideVideoImgMob} alt="" />
                  </picture>
                  </div>
                  <a href="#" className="video-arrow" onClick = { e => {setPlaying(true)}}><i class="icon-video-white"></i></a>
                </div>
                <div className="video-carouse-content">
                  <p className="name">Dalston</p>
                  <p className="code">E8, N16</p>
                  <p className="description">Up-and-coming</p>
                </div> 
              </div>
              </ScrollAnimation>
              
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
              <div className="item">
                <div className="video-img-box">
                  <div className="video-img-wrapper">
                  <picture>
                    <source media="(min-width:992px)" srcSet={AreaGuideVideoImgDesk} />
                    <source media="(min-width:768px)" srcSet={AreaGuideVideoImgTab} />
                    <img src={AreaGuideVideoImgMob} alt="" />
                  </picture>
                  </div>
                  <a href="#" className="video-arrow"  onClick = { e => {setPlaying(true)}}><i class="icon-video-white"></i></a>
                </div>
                <div className="video-carouse-content">
                  <p className="name">Dalston</p>
                  <p className="code">E8, N16</p>
                  <p className="description">Up-and-coming</p>
                </div> 
              </div>
              </ScrollAnimation>
              
              </Carousel>
          </div>
          <PlayVideo
              isOpen = { isPlay } 
              isCloseFunction = { setPlay }
              videoId="JFqiSr9A-Go"
              isAutoPlay={1}
            />
            <PlayVideo
              isOpen = { isPlaying } 
              isCloseFunction = { setPlaying }
              videoId="JFqiSr9A-Go"
              isAutoPlay={1}
            />
      </section>
    </React.Fragment>
  );
};

export default AreaGuides;
