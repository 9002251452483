import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,} from "react-bootstrap";
import "./RelatedPropertyManagement.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import relatedPropImg1Desk from "../../../images/PropertyDetails/related-prop-img1.jpg";
import relatedPropImg1Tab from "../../../images/PropertyDetails/related-prop-img1.jpg";
import relatedPropImg1Mob from "../../../images/PropertyDetails/related-prop-img1.jpg";

import relatedPropImg2Desk from "../../../images/PropertyDetails/related-prop-img2.jpg";
import relatedPropImg2Tab from "../../../images/PropertyDetails/related-prop-img2.jpg";
import relatedPropImg2Mob from "../../../images/PropertyDetails/related-prop-img2.jpg";

import relatedPropImg3Desk from "../../../images/PropertyDetails/related-prop-img3.jpg";
import relatedPropImg3Tab from "../../../images/PropertyDetails/related-prop-img3.jpg";
import relatedPropImg3Mob from "../../../images/PropertyDetails/related-prop-img3.jpg";

import enquiryImgDesk1 from "../../../images/enquiry-img2.png";
import enquiryImgTab1 from "../../../images/enquiry-img2.png";
import enquiryImgMob1 from "../../../images/enquiry-img2.png";

import enquiryImgDesk2 from "../../../images/enquiry-img4.png";
import enquiryImgTab2 from "../../../images/enquiry-img4.png";
import enquiryImgMob2 from "../../../images/enquiry-img4.png";

import enquiryImgDesk3 from "../../../images/enquiry-img3.png";
import enquiryImgTab3 from "../../../images/enquiry-img3.png";
import enquiryImgMob3 from "../../../images/enquiry-img3.png";

const RelatedPropertyManagement = (props) => {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3.2,
      partialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
      partialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  
  return (
    <React.Fragment>
      <section className="related-properties-management">
        <Container>
          <Row>
            <Col sm={12}>
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp" duration={0.5}>
              <h2>Properties that may interest you in Hackney, London</h2>
              <p className="related-prop-intro">Lorem ipsum dolor sit amet, consectetur adipiscing elit aenean.</p>
            </ScrollAnimation>
              <Carousel centerMode={false} responsive={responsive} showDots={true} draggable={true} autoPlay={false} swipeable={true} infinite={true} removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
               <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp"  duration={0.5}>
              <div className="item">
                <div className="related-prop-img-block">
                <a to="#">
                  <picture>
                    <source media="(min-width:992px)" srcSet={relatedPropImg1Desk} />
                    <source media="(min-width:768px)" srcSet={relatedPropImg1Tab} />
                    <img src={relatedPropImg1Mob} alt="" />
                  </picture>
                </a>
                <a href="#" class="view-img"><i class="icon-view-img"></i><span>12</span></a>
                </div>
                
                <div className="related-prop-content">
                  <h2><a href="#" className="btn-text-link">Norcott Road, London, N16</a></h2>
                  <p className="amount">&#163;1,500,000</p>
                  <p className="description">4 bedroom terraced house for sale</p>
                  <div class="list-enquiry">
                  <picture>
                    <source media="(min-width:992px)" srcSet={enquiryImgDesk1} />
                    <source media="(min-width:768px)" srcSet={enquiryImgTab1} />
                    <img src={enquiryImgMob1} alt="" />
                  </picture>
                    <p class="enquiry-text"><span><a href="#" class="border-bottom-yellow">Book a Viewing</a> or call <a class="border-bottom-yellow-slide" href="tel:02079232720">0207 923 2720</a></span></p></div>
                </div>
              </div>
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
              <div className="item">
                <div className="related-prop-img-block">
                <a to="#">
                  <picture>
                    <source media="(min-width:992px)" srcSet={relatedPropImg2Desk} />
                    <source media="(min-width:768px)" srcSet={relatedPropImg2Tab} />
                    <img src={relatedPropImg2Mob} alt="" />
                  </picture>
                </a>
                <a href="#" class="view-img"><i class="icon-view-img"></i><span>11</span></a>
                </div>
                
                <div className="related-prop-content">
                  <h2><a href="#" className="btn-text-link">Reighton Road, London, E5</a></h2>
                  <p className="amount">&#163;1,295,000</p>
                  <p className="description">4 bedroom terraced house for sale</p>
                  <div class="list-enquiry">
                  <picture>
                    <source media="(min-width:992px)" srcSet={enquiryImgDesk2} />
                    <source media="(min-width:768px)" srcSet={enquiryImgTab2} />
                    <img src={enquiryImgMob2} alt="" />
                  </picture>
                    <p class="enquiry-text"><span><a href="#" class="border-bottom-yellow">Book a Viewing</a> or call <a class="border-bottom-yellow-slide" href="tel:02071023049<">0207 102 3049</a></span></p></div>
                </div>
              </div>
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
              <div className="item">
                <div className="related-prop-img-block">
                <a to="#">
                  <picture>
                    <source media="(min-width:992px)" srcSet={relatedPropImg3Desk} />
                    <source media="(min-width:768px)" srcSet={relatedPropImg3Tab} />
                    <img src={relatedPropImg3Mob} alt="" />
                  </picture>
                </a>
                <a href="#" class="view-img"><i class="icon-view-img"></i><span>19</span></a>
                </div>
                
                <div className="related-prop-content">
                  <h2><a href="#" className="btn-text-link">Carysfort Road, London, N16</a></h2>
                  <p className="amount">&#163;1,450,000</p>
                  <p className="description">4 bedroom terraced house for sale</p>
                  <div class="list-enquiry">
                  <picture>
                    <source media="(min-width:992px)" srcSet={enquiryImgDesk3} />
                    <source media="(min-width:768px)" srcSet={enquiryImgTab3} />
                    <img src={enquiryImgMob3} alt="" />
                  </picture>
                    <p class="enquiry-text"><span><a href="#" class="border-bottom-yellow">Book a Viewing</a> or call <a class="border-bottom-yellow-slide" href="tel:02071023049">0207 102 3049</a></span></p></div>
                </div>
              </div>
              </ScrollAnimation>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default RelatedPropertyManagement;
