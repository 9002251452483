import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,
} from "react-bootstrap";
import "./PropertyManagementSection.scss";


const PropertyManagementSection = (props) => {

  return (
    <React.Fragment>
      <section className="property-management">
        <Container>
          <Row>
            <Col lg={5}>
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn='fadeInUp' duration={0.5}>
              <span className="small-heading">Property Management</span>
              <h2><span>Hassle-free.</span><span>Expert.</span><span>Management.</span></h2>
              </ScrollAnimation>
            </Col>
            <Col lg={7}>
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn='fadeInUp' duration={0.5}>
              <div className="project-management-content">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet auctor libero eu pharetra. In ultrices finibus nisi, a laoreet est pulvinar a. Aliquam pharetra, turpis eget vestibulum finibus, metus dolor hendrerit quam, at fringilla.</p>
              <p>Vestibulum vitae odio nec urna dictum tincidunt. Aliquam pulvinar felis quis velit pretium semper. Pellentesque vel lacinia nibh. Donec ut orci id mauris aliquet faucibus id vitae risus. Praesent id varius sapien, at eleifend nibh.</p>
              </div>
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn='fadeIn' duration={0.5}><div className="prop-management-btn d-md-none d-lg-block"><Link to="#" className="btn btn-outline" >Property Management Plans</Link></div>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="property-management-plans">
      
              <div className="pricing-container">
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn='fadeInUp' duration={0.5}>
                <div className="price-box">
                <div className="card-item-price price1">           
                <div className="plan-block  plan1">
                  <span className="recommend-tag">Recommended</span>
                  <h3>Managed</h3>
                  {/* <p className="small-intro">Consec tetur adipiscing elit ut dolor.</p> */}
                  <p className="tax-block">15% (12.5% + vat)</p>
                  <Link className="btn btn-dark" to="#">Get Started</Link>
                  <ul className="plan-details">
                    <li><i className="icon-check-small"></i> Everything Tenant Find includes</li>
                    <li><i className="icon-check-small"></i> Arrange gas and electrical safety checks</li>
                    <li><i className="icon-check-small"></i> Arrange installation of smoke and carbon monoxide alarms, professional cleaning and inventory and check in services.</li>
                    <li><i className="icon-check-small"></i> Collect and remit the monthly rent received</li>
                    <li><i className="icon-check-small"></i> Pursue non-payment of rent and provide advice on rent arrears actions</li>
                    <li><i className="icon-check-small"></i> Deduct commission and other works from rental income</li>
                    <li><i className="icon-check-small"></i> Advise all relevant council and utility providers of changes</li>
                    <li><i className="icon-check-small"></i> Undertake and report property visits in accordance with agreed terms</li>
                    <li><i className="icon-check-small"></i> Arrange routine repairs and instruct approved contractors</li>
                    <li><i className="icon-check-small"></i> Hold keys throughout the tenancy term</li>
                    <li><i className="icon-check-small"></i> Deal with rent reviews and tenancy renewals</li>
                    <li><i className="icon-check-small"></i> Serve notice for possession</li>
                    <li><i className="icon-check-small"></i> Arrange the Check Out, negotiate disbursement of the security deposit</li>
                  </ul>
                </div>
                </div>  
                <div className="card-item-price  price2">    
                <div className="plan-block plan2">
                  <h3>Collection</h3>
                  {/* <p className="small-intro">Lorem ipsum dolor sit amet consectetu.</p> */}
                  <p className="tax-block">12% (10% + vat)</p>
                  <Link className="btn btn-outline" to="#">Get Started</Link>
                  <ul className="plan-details">
                    <li><i className="icon-check-small"></i> Agree the market rent and find a tenant in accordance with the landlord guidelines</li>
                    <li><i className="icon-check-small"></i> Advise on refurbishment</li>
                    <li><i className="icon-check-small"></i> Provide guidance on compliance with statutory provisions and letting consents</li>
                    <li><i className="icon-check-small"></i> Carry out conducted viewings (as Appropriate)</li>
                    <li><i className="icon-check-small"></i> Market the property and advertise on relevant portals</li>
                    <li><i className="icon-check-small"></i> Erect board outside property in accordance with Town and Country Planning Act 1990</li>
                    <li><i className="icon-check-small"></i> Advise on non-resident tax status and HMRC (if relevant)</li>
                    <li><i className="icon-check-small"></i> Collect and remit the monthly rent received</li>
                    <li><i className="icon-check-small"></i> Pursue non-payment of rent and provide advice on rent arrears actions</li>
                    <li><i className="icon-check-small"></i> Deduct commission from rental income</li>
                  </ul>
                </div>
                </div>
                <div className="card-item-price price3">   
                <div className="plan-block plan3">
                  <h3>Finder</h3>
                  {/* <p className="small-intro">In ut dolor vestibulum finibus tortor at.</p> */}
                  <p className="tax-block">9.6% (8% + vat)</p>
                  <Link className="btn btn-outline" to="#">Get Started</Link>
                  <ul className="plan-details">
                    <li><i className="icon-check-small"></i> Agree the market rent and find a tenant in accordance with the landlord guidelines</li>
                    <li><i className="icon-check-small"></i> Advise on refurbishment</li>
                    <li><i className="icon-check-small"></i> Provide guidance on compliance with statutory provisions and letting consents</li>
                    <li><i className="icon-check-small"></i> Carry out conducted viewings (as Appropriate)</li>
                    <li><i className="icon-check-small"></i> Market the property and advertise on relevant portals</li>
                    <li><i className="icon-check-small"></i> Erect board outside property in accordance with Town and Country Planning Act 1990</li>
                    <li><i className="icon-check-small"></i> Advise on non-resident tax status and HMRC (if relevant)</li>
                  </ul>
                </div>
                </div>
                </div>
                </ScrollAnimation>  
              </div>
   
      </section>
    </React.Fragment>
  );
};

export default PropertyManagementSection;
