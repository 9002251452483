import React, { useEffect, useState, useRef } from "react";
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,} from "react-bootstrap";
import "./QuizContacts.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const QuizQuestions = (props) => {
  
  return (
    <React.Fragment>
      <section className="quiz-contacts">
        <Container>
          <Row>
            <Col lg={3} md={6} lg={6} xl={3}>
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
              <div className="contact-blocks">
                <div className="contact-block-inner">
                <div className="contact-head"><i className="icon-phone-mobile d-xl-none"></i> <i className="icon-phone d-none d-xl-block"></i>Telephone</div>
                <p className="description d-none d-md-block">Lorem ipsum dolor sit amet consectetur adipiscing.</p>
                <Link href="tel:02079239222" className="btn btn-outline">0207 923 9222</Link>
                </div>
              </div>
            </ScrollAnimation>
            </Col>

            <Col lg={3} md={6} lg={6} xl={3}>
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
              <div className="contact-blocks">
                <div className="contact-block-inner">
                <div className="contact-head"><i className="icon-email-mobile d-xl-none"></i> <i className="icon-email d-none d-xl-block"></i>Email</div>
                <p className="description  d-none d-md-block">Lorem ipsum dolor sit amet cetur <Link href="mailto:hello@locationlocation.com." className="btn-text-link">hello@locationlocation.com.</Link></p>
                <Link href="mailto:" className="btn btn-outline"  >Email Us</Link>
                </div>
              </div>
              </ScrollAnimation>
            </Col>

            <Col lg={3} md={6} lg={6} xl={3}>
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp" >
              <div className="contact-blocks">
                <div className="contact-block-inner">
                <div className="contact-head"><i className="icon-map-marker-mobile d-xl-none"></i> <i className="icon-map-marker d-none d-xl-block"></i>Our Office</div>
                <p className="description d-none d-md-block">18 Stoke Newington, Church Street, Stoke Newington, N16 0LU </p>
                <Link href="#" className="btn btn-outline"  >View on a map</Link>
                </div>
              </div>
            </ScrollAnimation>
            </Col>

            <Col lg={3} md={6} lg={6} xl={3}>
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
              <div className="contact-blocks">
                <div className="contact-block-inner">
                <div className="contact-head"><i className="icon-live-chat-mobile d-xl-none"></i> <i className="icon-live-chat d-none d-xl-block"></i>Live Chat</div>
                <p className="description  d-none d-md-block">Lorem ipsum dolor sit amet consectetur adipiscing.</p>
                <Link href="#" className="btn btn-outline">Start Chatting</Link>
                </div>
              </div>
              </ScrollAnimation>
            </Col>
            
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default QuizQuestions;
